import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="mailto:abakhus@proton.me">abakhus@proton.me</a>
        </li>
        <li>
          <a target="_blank" href="https://github.com/Abakhus/dApp/raw/main/Abakhus_Whitepaper_v1.pdf">Whitepaper</a>
        </li>
        <li>
          <a target="_blank" href="https://bazaar.abakhus.io/">Mainnet (Beta)</a>
        </li>
        <li>
          <a target="_blank" href="https://github.com/Abakhus/dApp/">Abakhus Github</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;